<template>
  <div class="AddActivity">
    <div style="padding-top: 9px">
      <div
        v-if="activityResources.length !== 0"
        style="width: 100%; height: 150px; overflow: hidden; margin-bottom: 5px"
      >
        <img
          v-if="activePictureType === 'image'"
          :src="activityResources[0]"
          alt=""
          style="width: 100%"
        />

        <el-carousel
          v-else-if="activePictureType === 'swiper'"
          trigger="click"
          height="150px"
          width="100%"
        >
          <el-carousel-item
            v-for="(item, index) in activityResources"
            :key="index"
          >
            <img :src="item" alt="" style="width: 100%" />
          </el-carousel-item>
        </el-carousel>

        <video
          v-else-if="activePictureType === 'video' && activityResources"
          :src="activityResources[0]"
          controls
          width="100%"
          height="150"
        ></video>
      </div>

      <div
        v-if="!activityResources.length !== 0"
        style="
          box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
          background: #fff;
          margin: 0 13px 0;
          padding: 0 15px 0 21px;
          height: 30px;
          box-sizing: border-box;
          color: #3a63db;
          font-size: 12px;
          display: flex;
          justify-content: space-between;
          align-items: center;
        "
      >
        <div>头图图片-可以默认-等待设计师设计</div>
        <!-- <div>替换</div> -->
        <el-upload
          class="upload-demo"
          accept=".*"
          action=""
          :http-request="httpRequest"
          :before-upload="beforeUploadActivityResources"
          :on-success="onSuccessActivityResources"
        >
          <span>替换</span>
        </el-upload>
      </div>
    </div>

    <div style="padding-top: 12px">
      <div
        style="
          box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
          background: #fff;
          margin: 0 13px 0;
          padding: 15px 20px 9px;
          box-sizing: border-box;
          color: #3a63db;
          font-size: 12px;
        "
      >
        <div style="color: #000; font-size: 14px; font-weight: bold">
          活动头图
        </div>

        <div
          style="
            margin-top: 11px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #343434;
            font-size: 12px;
          "
        >
          <div>模式</div>
          <el-radio-group v-model="activePictureType">
            <el-radio label="swiper" style="margin: 0 0 0 12px"
              >轮播图</el-radio
            >
            <el-radio label="image" style="margin: 0 0 0 12px">单图</el-radio>
            <el-radio label="video" style="margin: 0 0 0 12px">视频</el-radio>
          </el-radio-group>
        </div>

        <div
          style="
            margin-top: 12px;
            line-height: 11px;
            color: #d5a669;
            font-size: 10px;
          "
        >
          {{
            activePictureType === "swiper"
              ? "轮播图最多不超过6张"
              : activePictureType === "image"
              ? "单图会根据图片大小自适应高度，只能设置1张"
              : activePictureType === "video"
              ? "视频不大于30M"
              : ""
          }}
        </div>
      </div>
    </div>

    <div style="padding-top: 15px">
      <div
        style="
          box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
          background: #fff;
          margin: 0 13px 0;
          padding: 15px 20px 9px;
          box-sizing: border-box;
          color: #3a63db;
          font-size: 12px;
        "
      >
        <div style="color: #000; font-size: 14px; font-weight: bold">
          活动标题
        </div>
        <el-input
          style="margin-top: 7px"
          type="textarea"
          v-model="activityTitleVal"
          :rows="2"
          maxlength="35"
          show-word-limit
          placeholder="请输入标题名称"
        ></el-input>

        <div
          style="color: #000; font-size: 14px; font-weight: bold; margin: 8px 0"
        >
          仿LED滚动播放内容
        </div>
        <el-input
          v-model="broadcastContent"
          placeholder="请输入滚动播放内容"
        ></el-input>

        <div
          style="color: #000; font-size: 14px; font-weight: bold; margin: 8px 0"
        >
          活动时间
        </div>
        <div
          class="input-date"
          style="display: flex; align-items: center; justify-content: center"
        >
          <el-date-picker
            v-model="activityDate.startDate"
            value-format="yyyy-MM-dd HH:mm"
            type="datetime"
            placeholder="选择活动开始时间"
          ></el-date-picker>
          <div style="margin: 0 2px; color: #000">至</div>
          <el-date-picker
            v-model="activityDate.endDate"
            value-format="yyyy-MM-dd HH:mm"
            type="datetime"
            placeholder="选择活动结束时间"
          ></el-date-picker>
        </div>

        <div
          style="color: #000; font-size: 14px; font-weight: bold; margin: 8px 0"
        >
          喜报播报间隔
        </div>
        <div
          style="display: flex; align-items: center; justify-content: center"
        >
          <el-input
            v-model="set_voice_notify"
            placeholder="请输入喜报播报间隔"
          ></el-input>
        </div>
      </div>
    </div>

    <div style="padding-top: 16px">
      <div
        style="
          box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
          background: #fff;
          margin: 0 13px 0;
          padding: 15px 20px 9px;
          box-sizing: border-box;
          color: #3a63db;
          font-size: 12px;
        "
      >
        <div
          v-for="(item, index) in goodsList"
          :key="index"
          style="margin-top: 9px"
        >
          <div
            style="
              color: #000;
              font-size: 14px;
              margin: 5px 0;
              font-weight: bold;
              display: flex;
              align-items: center;
              justify-content: space-between;
            "
          >
            商品设置
            <i
              @click="handleDeleteGoods(index)"
              v-if="goodsList.length > 1"
              class="el-icon-error"
              style="font-size: 20px; opacity: 0.2"
            ></i>
          </div>

          <div style="display: flex; align-items: center">
            <!-- <div class="replace" style="background: #FF6000; width: 50px; height: 50px; margin-right: 17px; flex-shrink: 0;"></div> -->
            <el-upload
              class="upload-demo"
              accept=".*"
              action=""
              :http-request="httpRequest"
              :on-success="
                (response, file) => handleAvatarSuccess(response, file, index)
              "
              :before-upload="beforeAvatarUpload"
            >
              <div
                v-if="!item.goodsImage"
                class="replace"
                style="
                  background: #ff6000;
                  width: 50px;
                  height: 50px;
                  margin-right: 17px;
                  flex-shrink: 0;
                "
              ></div>
              <div
                v-else
                style="
                  height: 50px;
                  width: 50px;
                  margin-right: 17px;
                  overflow: hidden;
                "
              >
                <img :src="item.goodsImage" style="width: 100%" />
              </div>
            </el-upload>

            <el-input
              type="textarea"
              v-model="item.courseName"
              :rows="2"
              maxlength="30"
              show-word-limit
              placeholder="请输入课程名称"
            ></el-input>
          </div>

          <div class="shop-set-wrap">
            <div class="shop-set-item">
              <div>活动库存</div>
              <el-input
                class="small-input"
                v-model="item.activityCount"
              ></el-input>
              <div>件</div>
            </div>

            <div class="shop-set-item">
              <div>商品原价</div>
              <el-input
                class="small-input"
                v-model="item.commodityOldPrices"
              ></el-input>
              <div>元</div>
            </div>

            <div class="shop-set-item" style="width: 100%">
              <div>是否设置拼团人数上限</div>
              <!-- <el-input class="small-input" v-model="item.teamCount"></el-input> -->
              <el-checkbox
                style="margin-left: 10px"
                v-model="item.hasSetMaxPeopleNum"
                :true-label="1"
                :false-label="2"
                >{{ item.hasSetMaxPeopleNum === 1 ? "是" : "否" }}</el-checkbox
              >
            </div>
            <div class="shop-set-item">
              <div>成团人数</div>
              <el-input class="small-input" v-model="item.teamCount"></el-input>
              <div>个</div>
            </div>

            <div class="shop-set-item">
              <div>拼团价</div>
              <el-input class="small-input" v-model="item.teamPrice"></el-input>
              <div>元</div>
            </div>

            <!-- <div style="display: flex; align-items: center; margin-top: 11px">
              <div style="white-space: nowrap; align-self: flex-start">
                下单付款模式
              </div>
              <el-radio-group
                v-model="item.paymentType"
                style="margin-left: 10px"
              >
                <el-radio label="sale">下单付拼团价</el-radio>
                <el-radio
                  label="deposit"
                  style="
                    display: inline-flex;
                    align-items: center;
                    margin-top: 5px;
                  "
                >
                  <span>下单付</span>
                  <el-input
                    class="small-input"
                    v-model="item.deposit"
                  ></el-input>
                  <span>元定金</span>
                </el-radio>
              </el-radio-group>
            </div> -->

            <div class="shop-set-item">
              <div>一级分享奖励</div>
              <el-input
                class="small-input"
                v-model="item.levelOneshareRewards"
              ></el-input>
              <div>元</div>
            </div>

            <div class="shop-set-item">
              <div>二级分享奖励</div>
              <el-input
                class="small-input"
                v-model="item.levelTwoshareRewards"
              ></el-input>
              <div>元</div>
            </div>
          </div>
        </div>

        <div style="text-align: center; margin-top: 22px">
          <div
            @click="handleAddGoods"
            style="
              color: #fff;
              font-size: 10px;
              background: #4372fc;
              width: 103px;
              height: 28px;
              box-sizing: border-box;
              display: inline-flex;
              align-items: center;
              justify-content: center;
            "
          >
            + 新增商品
          </div>
        </div>
      </div>
    </div>

    <div style="padding-top: 15px">
      <div
        style="
          box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
          background: #fff;
          margin: 0 13px 0;
          padding: 15px 20px 9px;
          box-sizing: border-box;
          color: #3a63db;
          font-size: 12px;
        "
      >
        <div style="color: #000; font-size: 14px; font-weight: bold">
          团队福利
        </div>
        <el-input
          style="margin-top: 10px"
          type="text"
          placeholder="请输入成为团长福利"
          v-model="teamWelfare"
          maxlength="20"
          show-word-limit
        ></el-input>
      </div>
    </div>

    <div style="padding-top: 15px">
      <div
        style="
          box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
          background: #fff;
          margin: 0 13px 0;
          padding: 15px 20px 9px;
          box-sizing: border-box;
          color: #3a63db;
          font-size: 12px;
        "
      >
        <div style="color: #000; font-size: 14px; font-weight: bold">
          活动详情
        </div>
        <div style="color: #6b6b6b; font-size: 12px; margin-top: 10px">
          手机上仅能进行简单编辑需求，请需要详细设计将需求编辑好后提交到设计师审核时完善编辑。
        </div>
        <el-input
          style="margin-top: 7px"
          type="textarea"
          placeholder="请输入活动需求"
          :rows="10"
          v-model="activityRequirements"
          maxlength="300"
        ></el-input>
      </div>
    </div>

    <div style="padding-top: 15px">
      <div
        style="
          box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
          background: #fff;
          margin: 0 13px 0;
          padding: 15px 20px 9px;
          box-sizing: border-box;
          color: #3a63db;
          font-size: 12px;
        "
      >
        <div style="color: #000; font-size: 14px; font-weight: bold">
          联系方式设置
        </div>
        <div v-for="(item, index) in contactWaySettings" :key="index">
          <div
            style="
              color: #343434;
              font-size: 12px;
              margin-top: 10px;
              display: flex;
              align-items: center;
              justify-content: space-between;
            "
          >
            校区{{ index + 1 }}
            <i
              @click="handleDeleteContactWay(index)"
              v-if="contactWaySettings.length > 1"
              class="el-icon-error"
              style="font-size: 20px; opacity: 0.2"
            ></i>
          </div>

          <div
            style="
              display: flex;
              align-items: center;
              margin-top: 6px;
              color: #343434;
            "
          >
            <div style="min-width: 60px">校区名称</div>
            <el-input v-model="item.schoolName"></el-input>
          </div>

          <!-- <div
            style="
              display: flex;
              align-items: center;
              margin-top: 6px;
              color: #343434;
            "
          >
            <div style="min-width: 60px">校区地址</div>
            <div
              style="
                color: #606266;
                padding: 0 15px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 40px;
                box-sizing: border-box;
                border: 1px solid #dcdfe6;
                width: 100%;
              "
            >
              <div>
                {{ item.schoolAddress ? item.schoolAddress : "请选择地址" }}
              </div>
              <i
                @click="handleSelectAddress(index)"
                class="el-icon-location-information"
                style="font-size: 20px; padding: 5px 0 5px 5px"
              ></i>
            </div>
          </div> -->

          <div
            style="
              display: flex;
              align-items: center;
              margin-top: 6px;
              color: #343434;
            "
          >
            <div style="min-width: 60px">详细地址</div>
            <el-input v-model="item.detailedAddress"></el-input>
          </div>

          <div
            v-for="(item1, index1) in item.phoneNumbers"
            :key="index1"
            style="
              display: flex;
              align-items: center;
              margin-top: 6px;
              color: #343434;
            "
          >
            <div style="min-width: 60px">联系电话</div>
            <el-input v-model="item.phoneNumbers[index1].value">
              <el-button
                v-if="item.phoneNumbers.length > 1"
                slot="append"
                @click="delPhoneNumber(item.phoneNumbers, index1)"
                >删除</el-button
              >
            </el-input>
          </div>

          <div
            @click="handleAddPhoneNumbers(index)"
            style="color: #00a0e8; font-size: 12px; margin: 6px 0 0 60px"
          >
            + 联系电话
          </div>
        </div>

        <div
          @click="handleAddCampusAddress"
          style="
            background: #4279fc;
            color: #fff;
            font-size: 12px;
            margin: 19px auto 0;
            width: 119px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            box-sizing: border-box;
          "
        >
          + 添加校区
        </div>
      </div>
    </div>

    <div style="padding-top: 15px">
      <div
        style="
          box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
          background: #fff;
          margin: 0 13px 0;
          padding: 15px 20px 9px;
          box-sizing: border-box;
          color: #3a63db;
          font-size: 12px;
        "
      >
        <div style="color: #000; font-size: 14px; font-weight: bold">
          信息收集
        </div>

        <div
          v-for="(item, index) in collectInformations"
          :key="index"
          style="
            display: flex;
            align-items: center;
            justify-content: space-between;
          "
        >
          <template v-if="item.type === 'text'">
            <el-input
              style="margin-top: 10px"
              v-model="item.value"
              :placeholder="`${item.label}${
                item.isRequired ? '（默认必填）' : ''
              }`"
            ></el-input>
          </template>
          <template v-else-if="item.type === 'checkox'">
            <div>
              <div style="margin: 5px 0; color: #000">
                {{ item.label }}{{ item.isRequired ? "（默认必填）" : "" }}
              </div>
              <el-checkbox-group
                v-model="item.value"
                :max="item.maxSelectCount"
                style="display: inline-block; margin-right: 10px"
              >
                <el-checkbox
                  v-for="(item1, index1) in item.options"
                  :key="index1"
                  :label="index1"
                  name="type"
                  >{{ item1 }}</el-checkbox
                >
              </el-checkbox-group>
            </div>
          </template>
          <template v-else-if="item.type === 'raido'">
            <div>
              <div style="margin: 5px 0; color: #000">
                {{ item.label }}{{ item.isRequired ? "（默认必填）" : "" }}
              </div>
              <el-radio-group v-model="item.value">
                <el-radio
                  v-for="(item1, index1) in item.options"
                  :key="index1"
                  :label="index1"
                  >{{ item1 }}</el-radio
                >
              </el-radio-group>
            </div>
          </template>
          <i
            @click="handleDeleteInfo(index)"
            v-if="collectInformations.length > 1 && index !== 0 && index !== 1"
            class="el-icon-error"
            style="
              display: inline-block;
              margin-left: 5px;
              font-size: 20px;
              opacity: 0.2;
            "
          ></i>
        </div>

        <div
          style="
            margin-top: 10px;
            color: #00a0e8;
            font-size: 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
          "
        >
          <div
            @click="handldShowCollectInformation(0)"
            style="
              width: 92px;
              height: 27px;
              box-sizing: border-box;
              border-radius: 2px;
              border: 1px solid #00a0e8;
              line-height: 25px;
              text-align: center;
            "
          >
            + 单选项
          </div>
          <div
            @click="handldShowCollectInformation(1)"
            style="
              width: 92px;
              height: 27px;
              box-sizing: border-box;
              border-radius: 2px;
              border: 1px solid #00a0e8;
              line-height: 25px;
              text-align: center;
            "
          >
            + 多选项
          </div>
          <div
            @click="handldShowCollectInformation(2)"
            style="
              width: 92px;
              height: 27px;
              box-sizing: border-box;
              border-radius: 2px;
              border: 1px solid #00a0e8;
              line-height: 25px;
              text-align: center;
            "
          >
            + 文本项
          </div>
        </div>
      </div>
    </div>

    <div style="padding-bottom: 80px">
      <div
        @click="handleLaunchedCampaign"
        style="
          width: 155px;
          height: 32px;
          box-sizing: border-box;
          line-height: 32px;
          text-align: center;
          background: #4279fc;
          color: #fff;
          font-size: 12px;
          margin: 18px auto 0;
        "
      >
        发起活动
      </div>
    </div>

    <!-- 弹框 -->
    <el-drawer
      :title="
        dialogType === 0
          ? '单选项设置'
          : dialogType === 1
          ? '多选项设置'
          : dialogType === 2
          ? '文本项设置'
          : ''
      "
      :visible.sync="isShowDialog"
      direction="btt"
      :before-close="handleClose"
    >
      <div style="color: #000; font-size: 12px; padding: 0 30px">
        <div
          style="
            display: flex;
            align-items: center;
            justify-content: space-between;
          "
        >
          <div>是否必填</div>
          <el-switch
            v-model="collectInformationData.isRequired"
            active-color="#13ce66"
          ></el-switch>
        </div>
        <div
          v-if="dialogType === 1"
          style="
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 5px;
          "
        >
          <div>最多选择</div>
          <div style="display: flex; align-items: center">
            <el-input
              class="small-input"
              v-model="collectInformationData.maxSelectCount"
              maxlength="2"
            ></el-input>
            <div>项</div>
          </div>
        </div>

        <div
          v-if="dialogType === 2"
          style="
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 15px;
          "
        >
          <el-input
            class="input-style"
            v-model="collectInformationData.textContent"
            maxlength="15"
            placeholder="输入想要收集的信息（最多15个字）"
          ></el-input>
        </div>

        <div
          v-else-if="dialogType === 0 || dialogType === 1"
          style="margin-top: 15px; height: 170px; overflow-y: auto"
        >
          <el-input
            class="input-style"
            v-model="collectInformationData.question.title"
            maxlength="15"
            :placeholder="`输入${
              dialogType === 0 ? '单选' : '多选'
            }问题（最多15个字）`"
          ></el-input>

          <div
            v-for="(item, index) in collectInformationData.question.options"
            :key="index"
            style="
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-top: 10px;
            "
          >
            <el-input
              class="input-style border-input-none"
              v-model="collectInformationData.question.options[index]"
              :placeholder="`请输入选项${index + 1}`"
            ></el-input>
            <i
              @click="handleQuestionOptions(1, index)"
              class="el-icon-remove-outline"
              style="color: red; font-size: 15px"
            ></i>
          </div>

          <div
            @click="handleQuestionOptions(0)"
            style="
              line-height: 26px;
              color: #5e89fc;
              border-radius: 4px;
              border: 1px solid #5e89fc;
              font-size: 12px;
              width: 100px;
              height: 28px;
              box-sizing: border-box;
              text-align: center;
              margin: 10px auto 0;
            "
          >
            + 添加选项
          </div>
        </div>

        <div style="height: 50px"></div>
        <div
          @click="handleAddCollectInformation"
          style="
            position: absolute;
            width: 100%;
            left: 0;
            bottom: 0;
            background: #5e89fc;
            color: #fff;
            height: 35px;
            line-height: 35px;
            text-align: center;
          "
        >
          添加
        </div>
      </div>
    </el-drawer>

    <!-- 提示 -->
    <div v-if="isShowHint">
      <div
        style="
          z-index: 3001;
          color: #fff;
          font-weight: 400;
          font-size: 12px;
          position: fixed;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background: rgba(0, 0, 0, 0.6);
          border-radius: 4px;
          padding: 10px 15px;
        "
      >
        {{ hintContent }}
      </div>
    </div>
  </div>
</template> 

<script>
import OSS from 'ali-oss';
export default {
  name: "AddActivity",
  components: {

  },
  data() {
    return {
      // 是否显示提示
      isShowHint: false,
      // 提示内容
      hintContent: '',
      // 活动头图
      activityResources: [],
      // 活动图片类型
      activePictureType: 'image',
      // 活动标题
      activityTitleVal: '',
      // 滚动播放的内容
      broadcastContent: '',
      // 活动的时间
      activityDate: {
        startDate: '',
        endDate: '',
      },
      // 喜报播报间隔
      set_voice_notify: '',
      // 商品列表
      goodsList: [
        {
          // 商品图片
          goodsImage: '',
          // 课程名称
          courseName: '',
          // 活动库存
          activityCount: '',
          // 商品原价
          commodityOldPrices: '',
          hasSetMaxPeopleNum: 1,
          // 成团人数
          teamCount: '',
          // 拼团价
          teamPrice: '',
          // 下单付款模式
          // paymentType: 'sale',
          // paymentType: deposit 下单付多少定金
          // deposit: '',
          // 一级分享奖励
          levelOneshareRewards: '',
          // 二级分享奖励
          levelTwoshareRewards: '',
        }
      ],
      // 团队福利
      teamWelfare: '',
      // 活动需求
      activityRequirements: '',
      // 联系方式设置
      contactWaySettings: [
        {
          // 校区名称
          schoolName: '',
          // 校区地址
          // schoolAddress: "",
          // 详细地址
          detailedAddress: '',
          // 联系电话
          phoneNumbers: [{ value: "" }],
          // 坐标
          lat: '',
          lng: '',
        }
      ],
      // 信息收集的内容
      collectInformations: [
        {
          label: '学员姓名',
          value: '',
          isRequired: true,
          type: 'text',
        },
        {
          label: '联系人电话',
          value: '',
          isRequired: true,
          type: 'text',
        }
      ],
      // 是否显示底部弹框
      isShowDialog: false,
      // 当前弹框的类型 0：单选项设置  1：多选项设置  2：文本项设置
      dialogType: -1,
      // 弹框中表单内容
      collectInformationData: {
        isRequired: true,
        maxSelectCount: '',
        textContent: '',
        question: {
          title: '',
          options: [],
        },
      },
      client: null,
      // 活动id
      id: "",
      // 邀请人id
      invite_id: "",
    }
  },
  mounted() {
    if (this.$route.query.index && this.$route.query.index !== -1) {
      const { name, latng, city, index } = this.$route.query;

      this.handleDataInfo(1);
      this.$set(this.contactWaySettings, index, {
        ...this.contactWaySettings[index],
        // schoolAddress: addr,
        detailedAddress: name,
        lat: latng.split(",")[0],
        lng: latng.split(",")[1],
        city,
      });
      if (this.$route.query.id) {
        this.id = this.$route.query.id;
      }
    }
    else if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.invite_id = this.$route.query.invite_id ? this.$route.query.invite_id : "";
      this.getActivityDetails(parseInt(this.id));
    }
    else {
      this.handleDataInfo(1);
    }

    this.client = new OSS({
      region: 'oss-cn-beijing',
      accessKeyId: 'LTAI4FqCLakzf7Y7djy4ZAAL',
      accessKeySecret: 'zar1lbB808aGRHf5VHSZYqEFVnq55B',
      bucket: 'epintuan',
    });
  },
  methods: {
    /** 处理删除收集信息 */
    handleDeleteInfo(index) {
      this.collectInformations.splice(index, 1);
    },

    /** 处理删除校区 */
    handleDeleteContactWay(index) {
      this.contactWaySettings.splice(index, 1);
    },

    /** 处理商品的删除操作 */
    handleDeleteGoods(index) {
      this.goodsList.splice(index, 1);
    },

    /** 获取活动详情 */
    getActivityDetails(id) {
      let data = {};
      if (this.invite_id !== "") {
        data.invite_id = this.invite_id;
      }
      this.$axios.post("/events/getInfo", { id, ...data }).then(res => {
        this.activityResources = res.images.length !== 0 ? res.images[0].file_url.split(",") : [];
        this.activePictureType = res.image_type;
        this.activityTitleVal = res.name;
        this.broadcastContent = res.led_content;
        this.activityDate.startDate = res.begin_time ? res.begin_time : "";
        this.activityDate.endDate = res.end_time ? res.end_time : "";
        this.set_voice_notify = res.set_voice_notify
        this.goodsList = res.goods.map(item => ({
          courseName: item.good_name,
          goodsImage: item.good_image,
          activityCount: item.good_qty.toString(),
          commodityOldPrices: item.old_price.toString(),
          hasSetMaxPeopleNum: item.has_max_num,
          teamCount: item.tuan_member.toString(),
          teamPrice: item.sale_price.toString(),
          paymentType: item.pay_model.toString(),
          deposit: item.deposit_price ? item.deposit_price.toString() : "",
          levelOneshareRewards: item.reward_pid.toString(),
          levelTwoshareRewards: item.reward_pids.toString(),
          id: item.id,
        }));
        this.teamWelfare = res.tuan_ad_desc;
        this.activityRequirements = res.event_content;
        this.contactWaySettings = res.school.map(item => ({
          schoolName: item.name,
          // schoolAddress: item.address,
          detailedAddress: item.street,
          // phoneNumbers: item.telphone ? item.telphone.split(",").map(item1 => ({value: item1})) : [{value: ""}],
          phoneNumbers: JSON.parse(item.telphone),
          lat: item.lat ? item.lat : '',
          lng: item.lng ? item.lng : '',
          city: item.city ? item.city : '',
          id: item.id,
        }));
        this.collectInformations = JSON.parse(res.from_json);
      }).catch(error => {
        this.showMessage(error);
      });
    },

    /** 文件上传 */
    httpRequest(event) {
      return new Promise(resolve => {
        const file = event.file;
        if (!file)
          return false
        this.client.put(this.formatDate(new Date(), 1, "/") + "/" + file.uid + "." + file.type.substring(file.type.indexOf('/') + 1, file.type.length), file).then(res => {
          resolve(res);
        });
      });
    },

    /** 处理发起活动的事件 */
    handleLaunchedCampaign() {
      // 校验发送的参数是否合法
      if (this.clearStringSpace(this.activityTitleVal) === "") {
        this.showMessage('请输入活动标题');
        return;
      }
      else if (this.clearStringSpace(this.broadcastContent) === "") {
        this.showMessage('请输入仿LED滚动播放内容');
        return;
      }
      else if (this.clearStringSpace(this.activityDate.startDate) === "") {
        this.showMessage('请输入活动时间开始时间');
        return;
      }
      else if (this.clearStringSpace(this.activityDate.endDate) === "") {
        this.showMessage('请输入活动时间结束时间');
        return;
      }
      else if (this.clearStringSpace(String(this.set_voice_notify)) === "") {
        this.showMessage('请输入喜报播报间隔');
        return;
      }
      // 校验商品
      for (let item of this.goodsList) {
        // if(this.clearStringSpace(item.goodsImage) === "") {
        //     this.showMessage('请添加商品图片');
        //     return;
        // }
        // else 
        // if(this.clearStringSpace(item.courseName) === "") {
        //     this.showMessage('请输入商品标题');
        //     return;
        // }
        if (this.clearStringSpace(item.activityCount) === "") {
          this.showMessage('请输入活动库存');
          return;
        }
        else if (this.clearStringSpace(item.commodityOldPrices) === "") {
          this.showMessage('请输入商品原价');
          return;
        }
        else if (item.hasSetMaxPeopleNum === 1 && this.clearStringSpace(item.teamCount) === "") {
          this.$message('请输入成团人数');
          return;
        }
        else if (this.clearStringSpace(item.teamPrice) === "") {
          this.showMessage('请输入拼团价');
          return;
        }
        else if (item.deposit === "deposit" && this.clearStringSpace(item.deposit) === "") {
          this.showMessage('请输入定金');
          return;
        }
        else if (this.clearStringSpace(item.levelOneshareRewards) === "") {
          this.showMessage('请输入一级分享奖励');
          return;
        }
        else if (this.clearStringSpace(item.levelTwoshareRewards) === "") {
          this.showMessage('请输入二级分享奖励');
          return;
        }
      }

      if (this.clearStringSpace(this.teamWelfare) === "") {
        this.showMessage('请输入团长福利');
        return;
      }
      else if (this.clearStringSpace(this.activityRequirements) === "") {
        this.showMessage('请输入活动需求');
        return;
      }

      // 校验联系方式
      for (let item of this.contactWaySettings) {
        if (this.clearStringSpace(item.schoolName) === "") {
          this.showMessage('请输入校区名称');
          return;
        }
        // else if (this.clearStringSpace(item.schoolAddress) === "") {
        //   this.showMessage('请选择校区地址');
        //   return;
        // }
        else if (this.clearStringSpace(item.detailedAddress) === "") {
          this.showMessage('请输入详细地址');
          return;
        }
        else if (this.clearStringSpace(item.phoneNumbers[0].value) === "") {
          this.showMessage('请输入联系电话');
          return;
        }
      }

      // 发送请求...
      this.launchedCampaignInterface(this.handleSortingParameters());
    },

    /** 整理需要发送的参数 */
    handleSortingParameters() {
      // 整理需要发送的参数
      let data = {};

      // 整理商品列表的参数
      let goods = this.goodsList.map(item => {
        let data = {
          name: item.courseName,
          image: item.goodsImage,
          good_qty: parseInt(item.activityCount),
          old_price: parseFloat(item.commodityOldPrices),
          hasSetMaxPeopleNum: parseInt(item.hasSetMaxPeopleNum),
          tuan_member: parseInt(item.teamCount),
          sale_price: parseFloat(item.teamPrice),
          pay_model: item.paymentType,
          deposit_price: parseFloat(item.deposit),
          reward_pid: parseFloat(item.levelOneshareRewards),
          reward_pids: parseFloat(item.levelTwoshareRewards),
        };
        if (item.id) {
          data.id = item.id;
        }
        return data;
      });

      // 整理校区地址列表的参数
      let address = this.contactWaySettings.map(item => {
        let data = {
          name: item.schoolName,
          // address: item.schoolAddress,
          street: item.detailedAddress,
          telphone: JSON.stringify(item.phoneNumbers),
          // lat: item.lat ? item.lat : '',
          // lng: item.lng ? item.lng : '',
          // city: item.city,
        };
        if (item.id) {
          data.id = item.id;
        }
        return data;
      });

      let image_path = "";
      if (this.activePictureType === 'image' || this.activePictureType === 'video') {
        image_path = this.activityResources[0]
      }
      else {
        this.activityResources.forEach(item => {
          image_path += item + ","
        });
        image_path = image_path.substring(0, image_path.length - 1);
      }

      data = {
        image_path,
        image_type: this.activePictureType,
        name: this.activityTitleVal,
        led_content: this.broadcastContent,
        begin_time: this.activityDate.startDate,
        end_time: this.activityDate.endDate,
        set_voice_notify: this.set_voice_notify,
        goods: JSON.stringify(goods),
        tuan_ad_desc: this.teamWelfare,
        event_content: this.activityRequirements,
        address: JSON.stringify(address),
        from_json: JSON.stringify(this.collectInformations),
      }

      // 是否是编辑
      if (this.id) {
        data.id = this.id;
      }

      return data;
    },

    /** 调用发起活动的接口 */
    launchedCampaignInterface(data) {
      this.$axios.post("/events/saveEvent", { ...data }).then(() => {
        this.$router.replace({ name: 'Management' });
      }).catch(error => {
        this.showMessage(error);
      });
    },

    /** 文件上传成功时的钩子 */
    handleAvatarSuccess(res, file, index) {
      this.goodsList[index].goodsImage = res.url;
    },

    /** 上传文件之前的钩子 */
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isPNG = file.type === 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG && !isPNG) {
        this.showMessage('上传图片只能是 JPG 或者 png 格式!');
      }
      if (!isLt2M) {
        this.showMessage('上传图片大小不能超过 2MB!');
      }
      return (isJPG || isPNG) && isLt2M;
    },

    /** 上传头部资源成功时 */
    onSuccessActivityResources(res) {
      if (this.activePictureType === 'swiper') {
        this.activityResources.push(res.url);
      }
      else if (this.activePictureType === 'image' || this.activePictureType === 'video') {
        this.$set(this.activityResources, 0, res.url);
      }
    },

    /** 上传头部资源之前的钩子 */
    beforeUploadActivityResources(file) {
      const isJPG = file.type === 'image/jpeg';
      const isPNG = file.type === 'image/png';
      const isVideo = file.type.indexOf('video/') !== -1;
      const isLt2M = file.size / 1024 / 1024 < 2;
      const isLt35M = file.size / 1024 / 1024 < 35;

      if (this.activePictureType === 'image' || this.activePictureType === 'swiper') {
        if (!isJPG && !isPNG) {
          this.showMessage('上传图片只能是 JPG 或者 png 格式!');
          return (isJPG || isPNG);
        }
        if (!isLt2M) {
          this.showMessage('上传大小不能超过 2MB!');
          return isLt2M;
        }
        if (this.activePictureType === 'swiper' && this.activityResources.length >= 6) {
          this.showMessage('上传轮播图最多不超过6张');
          return false;
        }
      }
      else if (this.activePictureType === 'video') {
        if (!isVideo) {
          this.showMessage('上传的资源只能是视频');
          return isVideo;
        }
        if (!isLt35M) {
          this.showMessage('上传大小不能超过 35MB!');
          return isLt35M;
        }
      }
    },

    /** 处理添加商品 */
    handleAddGoods() {
      this.goodsList.push({
        // 课程名称
        courseName: '',
        // 活动库存
        activityCount: '',
        // 商品原价
        commodityOldPrices: '',
        hasSetMaxPeopleNum: 1,
        // 成团人数
        teamCount: '',
        // 拼团价
        teamPrice: '',
        // 下单付款模式
        paymentType: 0,
        // paymentType: 1 下单付多少定金
        deposit: '',
        // 一级分享奖励
        levelOneshareRewards: '',
        // 二级分享奖励
        levelTwoshareRewards: '',
      });
    },

    /** 处理问题选项  type: 0: 添加 1: 删除 */
    handleQuestionOptions(type, index) {
      if (type === 0) {
        this.collectInformationData.question.options.push('');
      }
      else if (type === 1) {
        this.collectInformationData.question.options.splice(index, 1);
      }
    },

    /** 处理添加联系电话 */
    handleAddPhoneNumbers(index) {
      // if (this.contactWaySettings[index].phoneNumbers.length < 3) {
      this.contactWaySettings[index].phoneNumbers.push({ value: "" });
      // }
    },
    /** 删除联系电话 */
    delPhoneNumber(item, index) {
      console.log(item, 'sdsdsd')
      item.splice(index, 1);
    },

    /** 选择地址 */
    handleSelectAddress(index) {
      let tempUrl = location.href.indexOf('?') === -1 ? location.href + "?index=" + index :
        location.href.substring(0, location.href.indexOf('?') + 1) + 'index=' + index + '&' + location.href.substring(location.href.indexOf('?') + 1, location.href.length);
      let strUrl = `https://apis.map.qq.com/tools/locpicker?search=1&type=0&backurl=${tempUrl}&key=${this.mapKey}&referer=myapp`;

      if (this.id && this.contactWaySettings[index].lat && this.contactWaySettings[index].lng) {
        strUrl += `&coord=${this.contactWaySettings[index].lat},${this.contactWaySettings[index].lng}`;
      }
      this.handleDataInfo(0);
      location.href = strUrl;
    },

    /** 对信息进行存储  -  0 设置  1 获取 */
    handleDataInfo(type) {
      if (type === 0) {
        const data = {
          activityResources: JSON.stringify(this.activityResources),
          activePictureType: this.activePictureType,
          activityTitleVal: this.activityTitleVal,
          broadcastContent: this.broadcastContent,
          activityDate: JSON.stringify(this.activityDate),
          set_voice_notify: this.set_voice_notify,
          goodsList: JSON.stringify(this.goodsList),
          teamWelfare: this.teamWelfare,
          activityRequirements: this.activityRequirements,
          contactWaySettings: JSON.stringify(this.contactWaySettings.map(item => ({ ...item, phoneNumbers: JSON.stringify(item.phoneNumbers) }))),
          collectInformations: JSON.stringify(this.collectInformations),
          id: this.id ? this.id : '',
        };

        localStorage.setItem('tempData', JSON.stringify(data));
      }
      else if (type === 1) {
        if (!localStorage.getItem('tempData')) {
          return;
        }
        const data = JSON.parse(localStorage.getItem('tempData'));
        localStorage.removeItem('tempData');

        this.activityResources = JSON.parse(data.activityResources);
        this.activePictureType = data.activePictureType;
        this.activityTitleVal = data.activityTitleVal;
        this.broadcastContent = data.broadcastContent;
        this.activityDate = JSON.parse(data.activityDate);
        this.set_voice_notify = data.set_voice_notify;
        this.goodsList = JSON.parse(data.goodsList);
        this.teamWelfare = data.teamWelfare;
        this.activityRequirements = data.activityRequirements;
        this.contactWaySettings = JSON.parse(data.contactWaySettings).map(item => ({ ...item, phoneNumbers: JSON.parse(item.phoneNumbers) }));
        this.collectInformations = JSON.parse(data.collectInformations);
        this.id = data.id;
      }
    },

    /** 处理添加校区地址 */
    handleAddCampusAddress() {
      // if (this.contactWaySettings.length < 3) {
      this.contactWaySettings.push({
        // 校区名称
        schoolName: '',
        // 校区地址
        schoolAddress: "",
        // 详细地址
        detailedAddress: '',
        // 联系电话
        phoneNumbers: [{ value: "" }],
      });
      // }
    },

    /** 处理点击添加收集信息 */
    handldShowCollectInformation(type) {
      this.collectInformationData = {
        isRequired: true,
        maxSelectCount: '',
        textContent: '',
        question: {
          title: '',
          options: [],
        }
      };

      this.dialogType = type;
      this.isShowDialog = true;
    },

    /** 处理确认添加收集信息 */
    handleAddCollectInformation() {
      let data = {};

      const { isRequired, maxSelectCount, textContent, question } = this.collectInformationData;

      if (this.dialogType === 2) {
        if (this.clearStringSpace(textContent) === "") {
          this.showMessage("请输入想要收集的信息");
          return;
        }

        data = {
          type: 'text',
          isRequired,
          label: textContent,
          value: '',
        }
      }
      else if (this.dialogType === 1) {
        if (this.clearStringSpace(question.title) === "") {
          this.showMessage("请输入多选问题");
          return;
        }
        else if (question.options.length === 0 || this.clearStringSpace(question.options[0]) === "") {
          this.showMessage("请填写选项");
          return;
        }

        data = {
          type: 'checkox',
          isRequired,
          label: question.title,
          options: question.options,
          value: [],
          maxSelectCount: maxSelectCount ? parseInt(maxSelectCount) : 1,
        }
      }
      else if (this.dialogType === 0) {
        if (this.clearStringSpace(question.title) === "") {
          this.showMessage("请输入单选问题");
          return;
        }
        else if (question.options.length === 0 || this.clearStringSpace(question.options[0]) === "") {
          this.showMessage("请填写选项");
          return;
        }

        data = {
          type: 'raido',
          isRequired,
          label: question.title,
          options: question.options,
          value: '',
        }
      }

      this.collectInformations.push(data);
      this.isShowDialog = false;
    },

    /** 统一处理关闭对话框 */
    handleClose(done) {
      done();
    },

    /** 去掉字符串左右空格 */
    clearStringSpace(string) {
      string = string ? string : "";
      return string.trimStart().trimEnd();
    },

    /** 格式化时间 */
    formatDate(date, index, segmentation = "-") {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().length < 2 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
      const day = date.getDate().toString().length < 2 ? '0' + date.getDate() : date.getDate();
      const hours = date.getHours().toString().length < 2 ? '0' + date.getHours() : date.getHours();
      const minutes = date.getMinutes().toString().length < 2 ? '0' + date.getMinutes() : date.getMinutes();
      const seconds = date.getSeconds().toString().length < 2 ? '0' + date.getSeconds() : date.getSeconds();

      switch (index) {
        case 1:
          return year + segmentation + month + segmentation + day;
        case 2:
          return year + segmentation + month + segmentation + day + " " + hours + ":" + minutes;
        case 3:
          return year + segmentation + month + segmentation + day + " " + hours + ":" + minutes + ":" + seconds;
        case 4:
          return month + segmentation + day + " " + hours + ":" + minutes;
        case 5:
          return hours + ":" + minutes
        default:
          return null;
      }
    },

    /** 显示提示 */
    showMessage(message) {
      this.hintContent = message;
      this.isShowHint = true;
      const timeout = setTimeout(() => {
        this.isShowHint = false;
        clearTimeout(timeout);
      }, 1000);
    },
  }
};
</script>

<style scoped lang="scss">
.AddActivity {
  background: #f5f5f5;
  min-height: 100vh;
  height: 100%;
}

::v-deep .el-radio {
  &:active {
    background: transparent;
  }
  &:focus {
    background: transparent;
  }
}

.input-date {
  ::v-deep {
    input {
      padding: 0 10px;
      font-size: 12px;
      text-align: center;
    }
    .el-input__prefix {
      display: none;
    }
  }
}

.replace {
  position: relative;
  &::after {
    content: "更换";
    position: absolute;
    left: 0;
    bottom: 0;
    background: rgb(0, 0, 0, 0.7);
    height: 15px;
    width: 100%;
    color: #fff;
    font-size: 8px;
    line-height: 15px;
    text-align: center;
  }
}

.shop-set-wrap {
  font-size: 12px;
  color: #343434;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}
.shop-set-item {
  display: flex;
  align-items: center;
  margin-top: 11px;
  width: 48%;
}

.small-input {
  flex: 1;
  width: 44px;
  margin: 0 6px;
  ::v-deep input {
    padding: 0 4px;
    text-align: center;
    height: 25px;
    line-height: 25px;
    font-size: 10px;
  }
}

::v-deep .el-drawer {
  border-radius: 15px 15px 0 0;
  height: auto !important;
  header {
    text-align: center;
    color: #000;
    font-size: 14px;
    font-weight: bold;
    margin-left: 32px;
    margin-bottom: 20px;
  }
}

.input-style {
  ::v-deep input {
    border: none;
    border-bottom: 1px solid #dcd9d9;
    height: 30px;
  }
}

.border-input-none {
  ::v-deep input {
    border: none !important;
  }
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  margin-right: 17px;
  border: 1px dashed #d9d9d9;
  font-size: 16px;
}
.avatar {
  width: 20px;
  height: 20px;
  display: block;
}

.upload-demo {
  ::v-deep ul {
    display: none;
  }
}
</style>
